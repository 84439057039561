import { Card, CardContent, CardCover, Typography } from "@mui/joy";
import React from "react";

export default function CardMenuItem({ title, photo }) {
    return (
        <Card 
            size="md" 
            sx={(theme) => ({
                height: '20vw', 
                maxHeight: 200, 
                minHeight: 140,
                borderRadius: 'xl',
                border: 'none',
                lineHeight: '17px',
                p: 2,
                [theme.breakpoints.down('small')]: {
                    p: 1
                }
            })}
        >
            <CardCover>
                <img src={photo} loading="lazy" alt=""/>
            </CardCover>
            <CardCover
                sx={{ background: 'rgba(0,0,0,0.2)'}}
            />
            <CardContent 
                sx={{ justifyContent: "flex-end", alignItems: "flex-start" }}
            >
                
                <Typography 
                    fontWeight={'bold'} 
                    level="title-md"
                    sx={(theme) => ({
                        lineHeight: 1.1,
                        backgroundColor: 'white', 
                        py: 1, px: 2, 
                        borderRadius: 'lg',
                        [theme.breakpoints.down('sm')]: {
                            fontSize: theme.fontSize.sm
                        }
                    })} 
                >      
                    {title}
                </Typography>
            </CardContent>
        </Card>
    )
}