import React, { useEffect, useMemo } from "react";
import Header from "../../components/UI/header/Header";
import Footer from "../../components/UI/footer/Footer";
import { Box, Stack, Typography } from '@mui/joy';
import NavigateHeader from "../../components/UI/navigate/NavigateHeader";

import { useLocation } from "react-router-dom";
import ModalProduct from "../../components/ModalProduct/ModalProduct";
import SnackBar from "../../components/UI/SnackBar";
import BrandFooter from "../../components/UI/brand_footer/BrandFooter";
import WebNavigateHeader from "../../components/UI/navigate/WebNavigateHeader";





export default function WebLayout({ children, openSnackSuccess, setOpenSnackSuccess, objModal, setObjModal, countBasketItem, setCountBasketItem, allCost, basket, setBasket, addBasket, removeBasket, createOrder, user, setUser, page_inform }) {
    const location = useLocation();

    

    // Хэширвоание Модального окна
    const modal = useMemo(() => {
        return <ModalProduct 
            modal={objModal} 
            setModal={setObjModal} 

            basket={basket}
            addBasket={addBasket}
            removeBasket={removeBasket} />
    }, [objModal, objModal.count])


    // Хэширвоание Снэкбара
    const snackBar = useMemo(() => {
        console.log('snackBar');
        return <SnackBar 
                    openSnackSuccess={openSnackSuccess}
                    setOpenSnackSuccess={setOpenSnackSuccess}
                >
                    <Stack spacing={0.5}>
                        <Typography sx={{ fontFamily: 'Nunito', fontWeight: 'bold' }} level="title-md">
                            Информация:
                        </Typography>
                        <Typography 
                            sx={{ fontFamily: 'Nunito' }} 
                            startDecorator={ 
                                <img style={{ objectFit: 'cover', height: '100%', borderRadius: '15px', maxWidth: '100%' }} 
                            src="../check_order.gif" alt=""/> }>
                            Заказ №10 успешно оформлен
                        </Typography>
                    </Stack>
                </SnackBar>
    }, [openSnackSuccess])


    // При переходе делать якорь-фиксацию
    useEffect(() => {
        if (location.hash) {
          const element = document.getElementById(location.hash.substring(1));
          if (element) {
                window.scrollTo({ 
                    top: element.offsetTop, 
                    behavior: 'smooth' 
                });
            } 
        }
        else window.scrollTo({ 
            top: 0, 
            behavior: 'smooth' 
        });
    }, [location]);
    

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <Box sx={{ bgcolor: 'white', position: 'sticky', top: '0', zIndex: 999 }}>
                    <WebNavigateHeader 
                        basket={basket}
                        setBasket={setBasket}
                        addBasket={addBasket}
                        removeBasket={removeBasket}
                        createOrder={createOrder}
                        countBask={countBasketItem} 
                        allCost={allCost}
                        user={user}
                        setUser={setUser}
                        page_inform={page_inform} />
                </Box>

                { children }
            </div>
            {modal}
        </div>
    )
}