import { AspectRatio, Box, Button, ButtonGroup, DialogContent, Modal, ModalClose, ModalDialog, Stack, Typography, useTheme } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


function ModalProduct ({ modal, setModal, addBasket, removeBasket }) {
    const theme = useTheme();
    
    console.log('Modal');

 
    function minusBask (){
        removeBasket(modal.count - 1, modal.product);
        modal.setCount(modal.count - 1);
        setModal({ ...modal, count: modal.count - 1 });
    }

    function plusBask (){
        addBasket(modal.count + 1, modal.setCount, modal.product);
        modal.setCount(modal.count + 1);
        setModal({ ...modal, count: modal.count + 1 });
    }

    return (
        <Transition in={modal.open} timeout={200}>
            {(state) => (
                <Modal
                    keepMounted
                    open={!['exited', 'exiting'].includes(state)}
                    onClose={() => setModal({...modal, open: false})}
                    slotProps={{
                        backdrop: {
                            sx: {
                            opacity: 0,
                            backdropFilter: 'none',
                            transition: `opacity 200ms, backdrop-filter 500ms`,
                            ...{
                                entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                            }[state],
                            },
                        },
                    }}
                    sx={{
                        visibility: state === 'exited' ? 'hidden' : 'visible',
                    }}
                >
                    <ModalDialog
                        sx={{
                            width: 600,
                            pt: 6,
                            [theme.breakpoints.down('sm')]: {
                                px: 1,
                                pb: 1,
                            },
                            borderRadius: 'xl',
                            opacity: 0,
                            transition: `opacity 200ms`,
                            ...{
                            entering: { opacity: 1 },
                            entered: { opacity: 1 },
                            }[state],
                        }}
                    >
                        <ModalClose style={{ zIndex: 2 }} />
                        <DialogContent>
                            <Stack
                                direction={'column'}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{ height: '100%' }}
                            >
                                <AspectRatio ratio="4/3" sx={{ width: '100%', borderRadius: 'xl' }}>
                                    <img src={modal.product.photo} loading="lazy" alt=""/>
                                </AspectRatio>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ px: 1 }}>
                                        <Typography 
                                            sx={{ textDecorationColor: 'red'}} 
                                            fontWeight={'bold'} 
                                            level="h2"
                                            >
                                                { modal.product.title }
                                        </Typography>
                                        <Typography fontWeight={'md'}>
                                            Вес: <Typography sx={{ color: '#C30013' }}>120г</Typography>
                                        </Typography>

                                        <Box sx={{ mt: 2 }}>
                                            <Stack 
                                                sx={{ width: '100%' }}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Stack
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="flex-start"
                                                    spacing={-0.8}
                                                >
                                                    <Typography>
                                                        Стоимость:
                                                    </Typography>
                                                    <Typography sx={{ px: 0, fontWeight: 'xl' }} level="h2">
                                                        { modal.product.price }₽
                                                    </Typography>
                                                </Stack>

                                                {
                                                    modal.count <= 0 ?
                                                    <Button
                                                        sx={{ fontSize: 'md' }}
                                                        onClick={() => plusBask()}>
                                                        В корзину
                                                    </Button>
                                                    :
                                                    <ButtonGroup sx={{ height: '100%' }} aria-label="outlined button group">
                                                        <Button 
                                                            sx={{ px: 1, borderRadius: 'var(--joy-radius-lg) 0 0 var(--joy-radius-lg)' }} 
                                                            variant="outlined"
                                                            onClick={() => minusBask()}>
                                                            <RemoveIcon sx={{ fontSize: 20, }} />
                                                        </Button>
                                                        <Button disabled sx={{ px: 2, py: 0.59 }}>
                                                            <Typography style={{ color: '#0084FF' }} level="body-lg">
                                                                { modal.count }
                                                            </Typography>
                                                        </Button>
                                                        <Button 
                                                            sx={{ px: 1, borderRadius: '0 var(--joy-radius-lg) var(--joy-radius-lg) 0' }} 
                                                            variant="outlined"
                                                            onClick={() => plusBask()}>
                                                            <AddIcon sx={{ fontSize: 20 }} />
                                                        </Button>
                                                    </ButtonGroup>
                                                }
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Box>
                            </Stack>
                        </DialogContent>
                    </ModalDialog>
                </Modal>
            )}
        </Transition>
    )
}

export default ModalProduct;