import { Box, Button, ButtonGroup, Stack, Typography, useTheme } from "@mui/joy";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useState } from "react";

function ItemBasket({ item, addBasket, removeBasket }) {
    const theme = useTheme();
    console.log('----- ItemBasket -----');
    console.log(item);
    const [ count, setCount ] = useState(item.count);

    function minusBask (){
        removeBasket(item.count - 1, item.product);
        item.setCount(item.count - 1);
        setCount(count - 1);
    }

    function delBask (){
        removeBasket(0, item.product);
        item.setCount(item.count - 1);
        setCount(count - 1);
    }

    function plusBask (){
        addBasket(item.count + 1, item.setCount, item.product);
        item.setCount(item.count + 1);
        setCount(count + 1);
    }

    return (
        <Stack
            sx={{ m: 3 }}
            justifyContent="space-between"
            alignItems="stretch"
            spacing={{ xs: 1, sm: 2 }}
            direction={{ xs: 'column', sm: 'row' }}
        >   
            <Stack
                direction='row'
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <img style={{ objectFit: 'cover', borderRadius: 'var(--joy-radius-xl)' }} loading='lazy' src={item.product.photo} width={100} height={100} alt="" />
                <Stack
                    sx={{ height: '100%' }}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                >
                    <Box>
                        <Typography level='title-lg' fontWeight='xl'>
                            {item.product.title}
                        </Typography>
                        <Typography fontWeight='md'>
                            Вес: <Typography sx={{ color: '#C30013' }}>120г</Typography>
                        </Typography>
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={-0.8}
                    >
                        <Typography>
                            Стоимость:
                        </Typography>
                        <Typography fontWeight='xl' level="h4">
                            {item.product.price} ₽
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0.8}
                >
                    <ButtonGroup aria-label="outlined button group">
                        <Button 
                            sx={{ px: 1, borderRadius: 'var(--joy-radius-lg) 0 0 var(--joy-radius-lg)' }} 
                            variant="outlined"
                            onClick={() => minusBask()}>
                            <RemoveIcon sx={{ fontSize: 20, }} />
                        </Button>
                        <Button disabled sx={{ px: 2, py: 0.45 }}>
                            <Typography style={{ color: '#0084FF' }} level="body-lg">
                                {item.count}
                            </Typography>
                        </Button>
                        <Button 
                            sx={{ px: 1, borderRadius: '0 var(--joy-radius-lg) var(--joy-radius-lg) 0' }} 
                            variant="outlined"
                            onClick={() => plusBask()}>
                            <AddIcon sx={{ fontSize: 20 }} />
                        </Button>
                    </ButtonGroup>

                    <Button 
                        color='danger'
                        sx={{ px: 1 }} 
                        variant="outlined"
                        onClick={() => delBask()}>
                        <DeleteOutlineIcon sx={{ fontSize: 20 }} />
                    </Button>
                </Stack>

        </Stack>
    )
}

export default ItemBasket;