


function Information(page_inform) {
    if (page_inform) {
        var _time_start = '';
        var _time_finish = '';
        if (page_inform.data.time_work_start) {
            var _datetime_start = new Date(page_inform.data.time_work_start);
            var hours = _datetime_start.getHours();
            var minutes = _datetime_start.getMinutes();
            _time_start = `${hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString()}`
        }
        if (page_inform.data.time_work_finish) {
            var _date_finish = new Date(page_inform.data.time_work_finish);
            var hours = _date_finish.getHours();
            var minutes = _date_finish.getMinutes();
            _time_finish = `${hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString()}`
        }
        return {
            phone: page_inform.data.phone,
            email: page_inform.data.email,
            address: page_inform.data.address,
            work_time: `${page_inform.data.work_days_week ? 'Без выходных' : 'Не работаем в выходные'} с ${_time_start} - ${_time_finish}`
        };
    }
    else return {};
};

export default Information;