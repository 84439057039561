import { Link as LinkRouter } from "react-router-dom";
import classes from "./CardMenuItem.module.css"
import CardMenuItem from "./CardMenuItem";

export default function LinkCardMenuItem({ title, photo, slugId }) {
    return (
        <LinkRouter className={classes.card_link} to={`/menu/${slugId}`}>
            <CardMenuItem title={title} photo={photo} />
        </LinkRouter>
    )
}