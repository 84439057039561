import { Box, Button, Card, CardContent, CardOverflow, Chip, Divider, Stack, Typography } from "@mui/joy";


function ItemOrder() {
    return (
        <Box sx={{ width: '100%', mb: 2 }}>
            <Card sx={{ p: 2, borderRadius: 'lg' }} variant="outlined">
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ mb: 1 }}
                    >
                        <Box>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography level="title-lg">
                                    Заказ №241 
                                </Typography>
                                <Chip variant="soft" color="success">
                                    Доставка
                                </Chip>
                            </Stack>
                            <Typography color="primary" level="body-sm">
                                г. Пермь, ул. Карпинского, дом 50, Россия
                            </Typography>
                        </Box>
                        <Typography>
                            10.07.2024
                        </Typography>
                    </Stack>
                    <Divider inset="context" />
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{ mt: 2, width: '100%' }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: '100%' }}
                        >
                            <Typography>
                                Суши эби Сэт
                            </Typography>

                            <Typography>
                                1 шт.
                            </Typography>

                            <Typography>
                                100 ₽
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: '100%' }}
                        >
                            <Typography>
                                Суши эби Сэт
                            </Typography>

                            <Typography>
                                1 шт.
                            </Typography>

                            <Typography>
                                100 ₽
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: '100%' }}
                        >
                            <Typography>
                                Суши эби Сэт
                            </Typography>

                            <Typography>
                                1 шт.
                            </Typography>

                            <Typography>
                                100 ₽
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
                <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>
                    <Divider inset="context" />
                    <CardContent sx={{ justifyContent: 'space-between', alignItems: '' }} orientation="horizontal">
                        <Stack>
                            <Button>
                                Повторить
                            </Button>
                        </Stack>

                        <Typography level="h4" fontWeight="md" textColor="text.secondary">
                            450 ₽
                        </Typography>
                    </CardContent>
                </CardOverflow>
            </Card>
        </Box>
    )
}


export default ItemOrder;