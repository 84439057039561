import { AspectRatio, aspectRatioClasses, Box, Button, ButtonGroup, Card, CardContent, CardCover, CardOverflow, Skeleton, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ModalItem from "../ModalProduct/ModalProduct";
import { Link as LinkRouter } from "react-router-dom";

function BigCardItem({ setObjModal, product, basket, setBasket, addBasket, removeBasket, ...props }) {
    const [ count, setCount ] = useState(0);
    // console.log('----- BigCardItem -----');

    // При Mount, отслеживаем есть ли товар в корзине
    useEffect(() => {
        const bask_id = basket.findIndex((element) => element.product.id === product.id)
        if (bask_id > -1) setCount(basket[bask_id].count);
        else setCount(0);
    });

    // Уменьшить товар в корзине
    function minusBask (){
        const rerer = removeBasket(count - 1, product);
        setCount(rerer);
    }
    // Увеличить товар в корзине
    function plusBask (){
        const rerer = addBasket(count + 1, setCount, product);
        setCount(rerer);
    }

    // Открыть модальное окно
    function openModalFunc() {
        setObjModal({open: true, product: product, count: count, setCount: setCount});
    }
    
    return (
        <Card variant="plain" size="sm"
            className={'animation-grid'} 
            sx={( theme ) => ({ 
                p: 0, 
                minHeight: '200px', 
                borderRadius: 'xl',
                '&:hover': {
                    '& .title': {
                        color: `${theme.colorSchemes.light.palette.danger[500]} !important`
                    }
                }
            })}>
            <CardOverflow>
                <AspectRatio ratio="2" sx={{
                    '& > .MuiAspectRatio-content': {
                        height: {
                            xs: 40,
                            sm: 60
                        }
                    }
                }}>
                    <Skeleton loading={false} variant="overlay">
                        <img onClick={() => openModalFunc()} style={{ cursor: 'pointer', borderRadius: 'xl' }} width={"100%"} src={product.photo} loading="lazy" alt=""/>
                    </Skeleton>
                </AspectRatio>
            </CardOverflow>
            <CardContent sx={{ py: 1, pt: 0.2, px: 2, mb: 1, justifyContent: "flex-end", alignItems: "flex-start" }}>
                <Box>
                    <Typography 
                        className={'title'}
                        sx={{ cursor: 'pointer', transition: '.4s' }}
                        textColor={'neutral.900'}
                        fontWeight={'bold'} 
                        level={"h4"}
                        onClick={() => openModalFunc()}
                        >
                        { product.title }
                    </Typography>
                    <Typography fontWeight={'medium'}>
                        Вес: <Typography textColor={'danger.400'}>120г</Typography>
                    </Typography>
                </Box>
                <Stack 
                    sx={{ mt: { xs: 0.8, md: 1, lg: 2 }, width: '100%' }}
                    direction={{ md: 'column', lg: "row" }}
                    justifyContent="space-between"
                    alignItems={{ md: "flex-start", lg: "center" }}
                    spacing={{ xs: 0.5, lg: 2 }}
                >
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={-0.8}
                    >
                        <Typography>
                            Стоимость:
                        </Typography>
                        <Typography sx={{ px: 0, fontWeight: 800 }} level="h4">
                            {product.price}₽
                        </Typography>
                    </Stack>

                    {
                        count <= 0 ?
                        <Button 
                            sx={{ fontSize: { xs: 'sm', sm: 'md' }, width: { xs: '100%', lg: 'auto' } }}
                            onClick={() => plusBask()}>
                            В корзину
                        </Button>
                        :
                        <ButtonGroup sx={{ height: '100%', width: { xs: '100%', lg: 'auto' } }} aria-label="outlined button group">
                            <Button 
                                sx={{ px: 1, width: { xs: '100%', lg: 'auto' }, borderRadius: 'var(--joy-radius-lg) 0 0 var(--joy-radius-lg)' }} 
                                variant="outlined"
                                onClick={() => minusBask()}>
                                <RemoveIcon sx={{ fontSize: 20, }} />
                            </Button>
                            <Button disabled sx={{ px: 2, py: 0.45, width: { xs: '100%', lg: 'auto' } }}>
                                <Typography style={{ color: '#0084FF' }} level="body-lg">
                                    {count}
                                </Typography>
                            </Button>
                            <Button 
                                sx={{ px: 1, width: { xs: '100%', lg: 'auto' }, borderRadius: '0 var(--joy-radius-lg) var(--joy-radius-lg) 0' }} 
                                variant="outlined"
                                onClick={() => plusBask()}>
                                <AddIcon sx={{ fontSize: 20 }} />
                            </Button>
                        </ButtonGroup>
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}

export default BigCardItem;