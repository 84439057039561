import { Snackbar, Stack, Typography, useTheme } from "@mui/joy";


function SnackBar({ children, openSnackSuccess, setOpenSnackSuccess }) {
    const theme = useTheme();
    
    return (
        <Snackbar
            autoHideDuration={6000}
            open={openSnackSuccess}
            anchorOrigin={{ 
                vertical: 'top', 
                horizontal: 'right',
                [theme.breakpoints.down('sm')]: {
                    horizontal: 'center',
                }
             }}
            onClose={() => setOpenSnackSuccess(false)}
            onUnmount={() => console.log('a')}
            sx={{ borderRadius: '15px', bgcolor: 'background.level1' }}
        >
            {children}
        </Snackbar>
    )
}

export default SnackBar;