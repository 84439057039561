import { Box, Chip, Container, CssVarsProvider, extendTheme, Grid, Stack, Typography, useTheme } from "@mui/joy";
import React, { useEffect, useMemo, useState } from "react";
import NavigateHeader from "../components/UI/navigate/NavigateHeader";
import { Link as LinkRouter, useParams } from "react-router-dom";
import ProductListScroll from "../components/MainPageUI/ProductListScrollX";
import BigCardItem from "../components/CardProductItem/BigCardItem";
import { useMediaQuery } from "@mui/material";

import { motion } from "framer-motion";


function MenuPage({ setObjModal, setCountBasketItem, basket, setBasket, addBasket, removeBasket, menu, page_inform }) {
    const { slugMenu } = useParams();
    // const theme = useTheme();

    const [ productsInMoreBaskets, setProductsInMoreBaskets ] = useState([
        { id: 1, title: "Сэн усури", photo: "../menu/sets.jpg", price: 450 },
        { id: 2, title: "Сэн усури", photo: "../menu/roll.png", price: 150 },
        { id: 3, title: "Фри эби сет", photo: "../menu/pizza.png", price: 250 },
        { id: 4, title: "Фри ролл", photo: "../menu/sets.jpg", price: 230 },
        { id: 5, title: "Суши", photo: "../menu/sushi.png", price: 540 }
    ]);


    useEffect(() => {
        window.scrollTo({ 
            top: 0, 
            behavior: 'smooth' 
        });
    }, []);

    const title = useMemo(() => {
        const menuItem = menu.findIndex((element) => element.slugId === slugMenu )
        if (menuItem > -1) return menu[menuItem].title;
        return 'Не определено';
    });

    // Кастомные брейкпоинты
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:900px)');
    const isLargeScreen = useMediaQuery('(max-width:1100px)');

    // Определяем количество колонок в зависимости от размера экрана
    const getColumns = () => {
        if (isSmallScreen) return 2;
        if (isMediumScreen) return 4;
        if (isLargeScreen) return 8;
        return 12;
    };


    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Container>
                <Typography level="h1" sx={{ my: 3 }}>
                    {title}
                </Typography>

                <Grid container spacing={1} sx={{ mb: 3 }}>
                    {menu.map(itm => 
                        <Grid xs='auto'>
                            <LinkRouter to={`/menu/${itm.id}`}>
                                <Chip color="primary" onClick={function(){}} variant="plain" key={itm.id} size={'md'}>
                                    {itm.name}
                                </Chip>
                            </LinkRouter>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, small: 4, sm: 6, medium: 6, md: 8, large: 10, lg: 12 }}
                    sx={{ flexGrow: 1, mb: 5 }}
                >
                    {productsInMoreBaskets.map((item, index) => 
                        <Grid xs={2} sm={3} medium={2} md={2} large={2.5} lg={3} key={index}>
                            <BigCardItem
                                setObjModal={setObjModal}
                                product={item}
                                basket={basket}
                                setBasket={setBasket}
                                addBasket={addBasket}
                                removeBasket={removeBasket}
                            />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </motion.div>
    )
}

export default MenuPage;