import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Card, Container, Divider, Grid, Link, Skeleton, Stack, Typography, useTheme } from '@mui/joy';

import DevicesIcon from '@mui/icons-material/Devices';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import BannerItem from "../components/MainPageUI/BannerItem/BannerItem";
import { useLocation } from "react-router-dom";
import MenuCardList from "../components/MainPageUI/MenuCardList";
import ProductListScroll from "../components/MainPageUI/ProductListScrollX";

import { motion } from "framer-motion";

// import Swiper core and required modules
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { usePrismicDocumentByUID, usePrismicDocumentsByType, useSinglePrismicDocument } from "@prismicio/react";
import Information from "../utils";




function MainPage({ menu, setObjModal, setCountBasketItem, basket, setBasket, addBasket, removeBasket, page_inform }) {
    const theme = useTheme();
    const location = useLocation();

    const [ productsInMoreBaskets, setProductsInMoreBaskets ] = useState([
        { id: 1, title: "Сэн усури", photo: "./menu/sets.jpg", price: 450 },
        { id: 2, title: "Сэн усури", photo: "./menu/roll.png", price: 150 },
        { id: 3, title: "Фри эби сет", photo: "./menu/pizza.png", price: 250 },
        { id: 4, title: "Фри ролл", photo: "./menu/sets.jpg", price: 230 },
        { id: 5, title: "Суши", photo: "./menu/sushi.png", price: 540 }
    ]);

    // const productsList = useMemo(() => {
    //     let __list = [];
    //     productsInMoreBaskets.forEach((itm) => {
    //         const [ count, setCount ]
    //         __list.push({itm, })
    //     });
    // });

    // // При переходе делать якорь-фиксацию
    // useEffect(() => {
    //     if (location.hash) {
    //       const element = document.getElementById(location.hash.substring(1));
    //       if (element) {
    //             window.scrollTo({ 
    //                 top: element.offsetTop, 
    //                 behavior: 'smooth' 
    //             });
    //         } 
    //     }
    // }, [location]);

    
    const banners_ads = useMemo(() => {
        if (page_inform) {
            console.log('GGGG', page_inform.data);
            return page_inform.data.banners_ads;
        }
        else return [];
    }, [page_inform]);

    const center_banner = useMemo(() => {
        if (page_inform) {
            return { 
                title: page_inform.data.title_center_banner1,
                description: page_inform.data.description_center_banner1,
                image: page_inform.data.image_center_banner.url 
            };
        }
        else return {};
    }, [page_inform]);

    const zone_delivery = useMemo(() => {
        if (page_inform) {
            return page_inform.data.zone_delivery.url;
        }
        else return undefined;
    }, [page_inform]);


    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);
    

    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Container
                sx={(theme) => ({
                    position: 'relative', py: 5,
                    [theme.breakpoints.down('sm')]: {
                        py: 3
                    }
                })}
            >
                {/* Блок быстрой рекламы */}
                {banners_ads != undefined ?
                    <Swiper
                            modules={[Autoplay, Pagination, A11y]}
                            spaceBetween={20}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 4000, pauseOnMouseEnter: true }}
                        >
                            {banners_ads.map(itm => 
                                    itm.view ? 
                                    <SwiperSlide>
                                        <BannerItem 
                                            title={itm.title} 
                                            text={itm.description} 
                                            // button={
                                            //     <Button sx={{ fontSize: 'xl', mt: 2, px: 4 }} color="neutral">
                                            //         Хочу!
                                            //     </Button>
                                            // } 
                                            image={itm.image.url}/>
                                    </SwiperSlide>
                                    :
                                    undefined
                                )}
                    </Swiper>
                    :
                    <Card variant="plain" sx={{ borderRadius: 'xl', justifyContent: 'center', minHeight: '300px', display: 'flex', gap: 2 }}>
                        <Typography sx={{ width: '50%' }}>
                            <Skeleton loading={true} sx={{ display: 'block' }}>
                                Текст Скилет
                            </Skeleton>
                        </Typography>
                        <Typography sx={{ width: '60%' }}>
                            <Skeleton loading={true} sx={{ display: 'block' }}>
                                Текст Скилет
                            </Skeleton>
                        </Typography>
                    </Card>
                    }
                
                {/* Блок шагов */}
                <Box 
                    className={"box-steps"}
                    sx={(theme) => ({
                        backgroundColor: 'white',
                        borderRadius: '15px', 
                        p: 5, mt: 5,
                        display: 'block',
                        [theme.breakpoints.down('md')]: {
                            display: 'none'
                        }
                    })}
                >
                    <Grid container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ flexGrow: 1 }}
                        columns={{ xs: 2, sm: 8, md: 12 }}
                        spacing={{ xs: 1, sm: 2 }}
                    >
                        <Grid xs={2} sm={2} md>
                            <Typography 
                                startDecorator={<DevicesIcon color="primary" fontSize="large" />}
                                level="h2"
                                sx={{ fontFamily: 'Nunito' }}
                            >
                                Заказываете
                            </Typography>
                            <Typography sx={{fontFamily: 'Nunito'}}>
                                Через сайт или нашего Telegram-бота <Link href="https://t.me/tsunami_perm_bot" sx={{ color: '#C30013', textDecorationColor: '#C30013' }}>@tsunami_perm_bot</Link>
                            </Typography>
                        </Grid>
                        <Grid xs={2} sm={1} md={0.6}>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <img className={"arrow"} src="./arrow_right.png" style={{ margin: 10 }}/>
                            </Stack>
                        </Grid>
                        <Grid xs={2} sm={2} md>
                            <Typography 
                                startDecorator={<LocalFireDepartmentIcon color="primary" fontSize="large" />} 
                                level="h2"
                                sx={{ fontFamily: 'Nunito' }}
                            >
                                Готовим
                            </Typography>
                            <Typography sx={{fontFamily: 'Nunito'}}>
                                Заказ готовится в течении <Typography sx={{ color: '#C30013' }}>40 мин</Typography>. За это время можно собрать пазлы
                            </Typography>
                        </Grid>
                        <Grid xs={2} sm={1} md={0.6}>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <img className={"arrow"} src="./arrow_right.png" style={{margin: 10}}/>
                            </Stack>
                        </Grid>
                        <Grid xs={2} sm={2} md>
                            <Typography 
                                startDecorator={<DeliveryDiningIcon color="primary" fontSize="large" />} 
                                level="h2"
                                sx={{ fontFamily: 'Nunito' }}
                            >
                                Доставляем
                            </Typography>    
                            <Typography sx={{fontFamily: 'Nunito'}}>
                                Наш курьер едет к вам чтобы отдать заказ еще <Typography sx={{ color: '#C30013' }}>горячим</Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                
                {/* Блок Меню */}
                <MenuCardList menuList={menu} />
                
                {/* Блок Товаров которые есть в корзинах */}
                <Box 
                    sx={(theme) => ({
                        mt: 5, flexGrow: 1,
                        [theme.breakpoints.down('sm')]: {
                            mt: 3
                        }
                    })}
                >
                    <Typography 
                        level="h2" 
                        sx={{ 
                            fontFamily: 'Nunito', 
                            fontWeight: 'bold', 
                            mb: 2
                            }}
                    >
                        У многих в корзине
                    </Typography>

                    <ProductListScroll 
                        productList={productsInMoreBaskets} 
                        basket={basket}
                        setBasket={setBasket}

                        setObjModal={setObjModal}
                        setCountBasketItem={setCountBasketItem}
                        addBasket={addBasket}
                        removeBasket={removeBasket}
                    />
                </Box>
                
                {/* Блок рекламы */}
                <Box
                    variant="plain"
                    sx={(theme) => ({
                        mt: 5, flexGrow: 1,
                        [theme.breakpoints.down('sm')]: {
                            mt: 3
                        }
                    })}
                >
                    <BannerItem 
                        title={center_banner.title} 
                        text={center_banner.description} 
                        // button={
                        //     <Button color="neutral">
                        //         Где вы находитесь?
                        //     </Button>
                        // } 
                        image={center_banner.image}/>
                </Box>
                    
                {/* Блок товаров-новинок */}
                <Box 
                    id={"new"}
                    sx={(theme) => ({
                        mt: 5,
                        [theme.breakpoints.down('sm')]: {
                            mt: 3
                        }
                    })}
                >
                    <Typography sx={{fontFamily: 'Nunito', fontWeight: 'bold', mb: 2}} level="h2">
                        Новинки!
                    </Typography>

                    <ProductListScroll 
                        productList={productsInMoreBaskets} 
                        basket={basket}
                        setBasket={setBasket}
                        
                        setObjModal={setObjModal}
                        setCountBasketItem={setCountBasketItem}
                        addBasket={addBasket}
                        removeBasket={removeBasket}
                    />
                </Box>
            </Container>
            
            {/* Блок контактов и зоны доставки */}
            <Box
                sx={(theme) => ({
                    py: 5, mt: 2, mb: 5, backgroundColor: "#fff",
                    [theme.breakpoints.down('sm')]: {
                        mt: 3,
                        py: 3
                    }
                })}
            >
                <Container>
                    <Stack
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={{ sm: 3, md: 5 }}
                        direction={{ xs: 'column', md: 'row' }}
                    >
                        <Box id={"delivery"} sx={{ mt: 2, position: "relative", overflow: "hidden", minWidth: '50%', width: { xs: '100%', md: '60%' } }}>
                            <Box sx={{ mb: 2 }}>
                                <Typography level="h2">
                                    Доставка
                                </Typography>
                                <Typography level="h4" color="danger">
                                    Минимальная сумма заказа: 1000 руб.
                                </Typography>
                                <Typography level="title-lg">
                                    Стоимость доставки:
                                </Typography>
                                <Typography level="body-lg">
                                    Красная зона (центр) - 150 руб.
                                </Typography>
                                <Typography level="body-lg">
                                    Красная зона (центр) - 150 руб.
                                </Typography>
                            </Box>
                            <Typography sx={{ width: '100%', mb: 3 }} level="h4">
                                Зона доставки
                            </Typography>
                            <a href="https://yandex.ru/maps/50/perm/?utm_medium=mapframe&utm_source=maps" style={{color: "#eee", fontSize: 12, position: "absolute", top: 0 }}>
                                
                            </a>
                            {/* <a href="https://yandex.ru/maps/50/perm/house/ulitsa_karpinskogo_50/YU8YdAZmSUAPQFttfXV5cn9gYA==/?ll=56.215079%2C57.983088&utm_medium=mapframe&utm_source=maps&z=17.58" style={{ color: "#eee", fontSize: 0, position: "absolute", top: 14}}>
                                Улица Карпинского, 50 — Яндекс Карты
                            </a> */}
                            {/* "https://yandex.ru/map-widget/v1/?um=constructor%3A20dd0395fc207cfc4473c2721e9abc11ad23ba7513f928f7070746ae7a509b5e&amp;source=constructor" */}
                            <iframe src={zone_delivery} height={"100%"} width={"100%"} style={{ borderRadius: '15px', border: "none", position: "relative", minHeight: '400px', minWidth: '50%' }}>
                            </iframe>
                        </Box>

                        <Box id={"contacts"} sx={{ width: { xs: '40% !important', md: 'auto' } }}>
                            <Box sx={{ mb: 2 }}>
                                <Typography level="h2">
                                    Самовывоз
                                </Typography>
                                <Typography level="h4" color="danger">
                                    От любой суммы
                                </Typography>
                                <Typography level="body-lg">
                                    Оплата заказа при получении
                                </Typography>
                            </Box>
                            <Divider/>
                            <Typography sx={{ mt: 2, width: '100%', fontWeight: 'bold', mb: 3 }} level="h4">
                                Контакты
                            </Typography>
                            
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                    <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.phone ? false : true }>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <PhoneIcon color="primary" sx={{ fontSize: 30 }}/>
                                            <Typography sx={{ fontFamily: 'Nunito', fontSize: 20, fontWeight: 'bold' }}>
                                                {inform.phone}
                                            </Typography>
                                            
                                        </Stack>
                                    </Skeleton>
                                </Box>

                                <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                    <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.email ? false : true }>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <AlternateEmailIcon color="primary" sx={{ fontSize: 30 }}/>
                                            <Typography sx={{ fontFamily: 'Nunito', fontSize: 20, fontWeight: 'bold' }}>
                                                {inform.email}
                                            </Typography>
                                            
                                        </Stack>
                                    </Skeleton>
                                </Box>

                                <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                    <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.address ? false : true }>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <LocationOnIcon color="primary" sx={{ fontSize: 30 }}/>
                                            <Typography sx={{ fontFamily: 'Nunito', fontSize: 20, fontWeight: 'bold' }}>
                                                {inform.address}
                                                {/* г. Пермь, ул. Карпинского, дом 50, Россия */}
                                            </Typography>
                                            
                                        </Stack>
                                    </Skeleton>
                                </Box>

                                <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                    <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.work_time ? false : true }>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <HistoryToggleOffIcon color="primary" sx={{ fontSize: 30 }}/>
                                            <Typography sx={{ fontFamily: 'Nunito', fontSize: 20, fontWeight: 'bold' }}>
                                                {inform.work_time}
                                                {/* Без выходных с 09:00-22:00 */}
                                                {/* г. Пермь, ул. Карпинского, дом 50, Россия */}
                                            </Typography>
                                            
                                        </Stack>
                                    </Skeleton>
                                </Box>

                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={0}
                                    sx={{ mb: 3 }}
                                >
                                    <Link href="/"><img style={{ fontSize: 30, width: 50 }} src={"./telegram_logo.svg"} /></Link>
                                    <Link href="/"><img style={{ fontSize: 30, width: 50 }} src={"./vk_logo.svg"} /></Link>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Container>
            </Box>


            {/* <Container sx={{ my: 7 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={3}
                >
                    <Box>
                        <Box sx={{ p: 3, backgroundColor: 'white', borderRadius: '15px' }}>
                            <Typography sx={{ fontFamily: 'Nunito', color: '#C30013' }} level="h2">
                                Куда вы можете доставить?
                            </Typography>
                            <Typography sx={{fontFamily: 'Nunito'}}>
                                Через сайт или нашего Telegram-бота <Link color="danger" href="https://t.me/tsunami_perm_bot">@tsunami_perm_bot</Link>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ position: "relative", overflow: "hidden", minWidth: '50%' }}>
                        <img src={"./cafe.jpg"} style={{ borderRadius: '15px' }} width={"100%"} />
                    </Box>
                </Stack>
            </Container> */}
        </motion.div>
    )
}

export default MainPage;