
import { Transition } from 'react-transition-group';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { Box, Button, Divider, Input, ModalClose, ModalOverflow, Radio, RadioGroup, Stack, Tab, tabClasses, TabList, TabPanel, Tabs, Typography, useTheme } from '@mui/joy';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';


import InputMask from 'react-input-mask';
import { useEffect, useMemo, useState } from 'react';
import ItemBasket from './ItemBasket';




export default function ModalBasket({ modalBasket, setModalBasket, basket, setBasket, addBasket, removeBasket, createOrder, ...props }) {
    const theme = useTheme();

    const tg = window.Telegram.WebApp;

    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ promo, setPromo ] = useState('');

    // Обновление списка корзины
    const listBasket = useMemo(() => {
        return basket.map((item) => 
            <ItemBasket item={item} removeBasket={removeBasket} addBasket={addBasket} key={item.product.id} />
        )
    }, [basket]);

    // Суммирование стоимости всех товаров
    const sumTotalCount = useMemo(() => {
        let summ = 0;
        basket.map((item) => summ += item.count * item.product.price)
        return summ
    }, [basket]);

    // Создание заказа
    function orderCreate(e) {
        e.preventDefault();
        setModalBasket(false);
        createOrder(true);
        tg.close();
    }

    // Если корзина пустая, закрываем окно
    useEffect(() => {
        if (basket.length < 1) setModalBasket(false);
    }, [basket]);


    return (
        <Transition in={modalBasket} timeout={500}>
            {(state) => (
                <Modal
                    keepMounted
                    open={!['exited', 'exiting'].includes(state)}
                    onClose={() => setModalBasket(false)}
                    slotProps={{
                        backdrop: {
                            sx: {
                                padding: 0,
                                opacity: 0,
                                backdropFilter: 'none',
                                transition: `opacity 200ms, backdrop-filter 500ms`,
                                ...{
                                    entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                    entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                                }[state],
                            },
                        },
                    }}
                    sx={{
                        visibility: state === 'exited' ? 'hidden' : 'visible',
                    }}
                >   
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <ModalDialog
                            sx={{
                                [theme.breakpoints.down("lg")]: {
                                    width: '70%',
                                    mx: 0,
                                },
                                [theme.breakpoints.down("md")]: {
                                    width: '90%',
                                    mx: 0,
                                },
                                [theme.breakpoints.down("sm")]: {
                                    width: '90%',
                                },
                                p: 0,
                                my: 5,
                                backgroundColor: 'transparent',
                                border: '0',
                                boxShadow: 'none',
                                opacity: 0,
                                transition: `opacity 200ms`,
                                ...{
                                    entering: { opacity: 1 },
                                    entered: { opacity: 1 },
                                }[state],
                            }}
                        >   
                            <ModalClose style={{ zIndex: 2 }} />
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                            >
                                <Box 
                                    sx={{ 
                                        width: '100%',
                                        backgroundColor: 'white',
                                        borderRadius: 'xl'
                                    }}
                                >
                                    <Typography startDecorator={<ShoppingCartIcon color='primary' />} fontSize='md' sx={{ px: 2, my: 1.5 }}>
                                        Корзина
                                    </Typography>
                                    <Divider />

                                    {listBasket}
                                </Box>
                                
                                
                                <Box 
                                    sx={{ 
                                        width: '100%',
                                        backgroundColor: 'white',
                                        borderRadius: 'xl'
                                    }}
                                >
                                    <Typography startDecorator={<SaveAltIcon color='primary' />} fontSize='md' sx={{ px: 2, my: 1.5 }}>
                                        Оформление заказа
                                    </Typography>
                                    <Divider/>
                                    
                                    <form style={{ width: '100%' }}>
                                        <Stack
                                            direction={{ xs: 'column', sm: 'row', md: 'row' }}
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            spacing={2}
                                            sx={{ m: 3 }}
                                        >
                                            <Box sx={{ width: '100%' }}>
                                                <Box>
                                                    <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                                        Имя
                                                    </Typography>
                                                    <Input type="text" sx={{ borderRadius: 'md' }} placeholder="Иван" variant="outlined" onChange={(e) => setName(e.target.value)} />
                                                </Box>

                                                <Box sx={{ mt: 2 }}>
                                                    <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                                        Номер телефона
                                                    </Typography>
                                                    <InputMask mask="+7 (999) 999 99-99" value={props.value} onChange={(e) => setPhone(e.target.value)}>
                                                        {(inputProps) => <Input {...inputProps} sx={{ borderRadius: 'md' }} type="tel" placeholder="+7 (000) 000 00-00" variant="outlined" disableUnderline />}
                                                    </InputMask>
                                                </Box>

                                                <Box sx={{ mt: 2 }}>
                                                    <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                                        Промокод
                                                    </Typography>
                                                    <Input type="text" sx={{ borderRadius: 'md', maxWidth: 100 }} placeholder="TSUNAMI" variant="outlined" onChange={(e) => setPromo(e.target.value.toUpperCase())} />
                                                </Box>
                                            </Box>
                                            
                                            <Tabs 
                                                aria-label="tabs" 
                                                defaultValue={0} 
                                                sx={{ width: '100%', my: 2, bgcolor: 'transparent' }}
                                            >
                                                <TabList
                                                    disableUnderline
                                                    sx={{
                                                        width: 'min-content',
                                                        [theme.breakpoints.down('sm')]: {
                                                            width: '100%'
                                                        },
                                                        [theme.breakpoints.up('md')]: {
                                                            width: '100%'
                                                        },
                                                        p: 0.5,
                                                        gap: 0.5,
                                                        borderRadius: 'xl',
                                                        bgcolor: 'background.level1',
                                                        [`& .${tabClasses.root}[aria-selected="true"]`]: {
                                                            boxShadow: 'sm',
                                                            bgcolor: 'background.surface',
                                                        },
                                                    }}
                                                >
                                                    <Tab disableIndicator sx={{
                                                        [theme.breakpoints.down('sm')]:{
                                                            width: '100%'
                                                        },
                                                        [theme.breakpoints.up('md')]:{
                                                            width: '100%'
                                                        },
                                                    }}>
                                                        Доставка
                                                    </Tab>
                                                    <Tab disableIndicator sx={{
                                                        [theme.breakpoints.down('sm')]:{
                                                            width: '100%'
                                                        },
                                                        [theme.breakpoints.up('md')]:{
                                                            width: '100%'
                                                        },
                                                    }}>
                                                        Самовывоз
                                                    </Tab>
                                                </TabList>

                                                <TabPanel value={0} sx={{ mt: 2, bgcolor: 'background.level1', borderRadius: 'xl', }}>
                                                    <Box>
                                                        <Typography sx={{ mb: 0.5 }} level="title-sm" textColor="text.secondary" fontWeight="xl">
                                                            Адрес
                                                        </Typography>
                                                        <Input sx={{ borderRadius: 'md' }}placeholder="Куйбышева 95Б, 1 п" variant="outlined" />
                                                    </Box>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography sx={{ mb: 0.5 }} level="title-sm" textColor="text.secondary" fontWeight="xl">
                                                            Комментарий к заказу
                                                        </Typography>
                                                        <Input sx={{ borderRadius: 'md' }} placeholder="Выйду сам" variant="outlined" />
                                                    </Box>
                                                </TabPanel>

                                                <TabPanel value={1} sx={{ mt: 2, bgcolor: 'background.level1', borderRadius: 'xl', }}>
                                                    <Typography level="inherit">
                                                        Наши кафе:
                                                    </Typography>
                                                    <RadioGroup sx={{ p: 1 }} defaultValue="value1" name="radio-buttons-group">
                                                        <Radio value="value1" label="г. Пермь, ул. Карпинского, дом 50, Россия" color="primary" />
                                                    </RadioGroup>
                                                </TabPanel>
                                            </Tabs>
                                        </Stack>
                                    </form>
                                </Box>


                                { name || phone || promo ?
                                    <Box 
                                        sx={{ 
                                            width: '100%',
                                            backgroundColor: 'white',
                                            borderRadius: 'xl'
                                        }}
                                    >
                                        <Typography startDecorator={<RamenDiningIcon color='primary' />} fontSize='md' sx={{ px: 2, my: 1.5 }}>
                                            Заказ
                                        </Typography>
                                        <Divider />
                                        
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="stretch"
                                            spacing={0.1}
                                            sx={{ mx: 3, my: 2 }}
                                        >
                                            { name !== '' ? 
                                                <Stack
                                                    sx={{ mx: 2 }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography level='title-md' fontWeight='lg'>
                                                        Имя:
                                                    </Typography>
                                                    <Typography level='body-md'>
                                                        {name}
                                                    </Typography>
                                                </Stack>
                                            : '' }

                                            { phone !== '' ? 
                                                <Stack
                                                    sx={{ mx: 2 }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography level='title-md' fontWeight='lg'>
                                                        Телефон: 
                                                    </Typography>
                                                    <Typography level='body-md'>
                                                        {phone}
                                                    </Typography>
                                                </Stack>
                                            : '' }

                                            { promo !== '' ? 
                                                <Stack
                                                    sx={{ mx: 2 }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography level='title-md' fontWeight="lg">
                                                        Промокод: 
                                                    </Typography>
                                                    <Typography level='body-md' color="primary">
                                                        {promo}
                                                    </Typography>
                                                </Stack>
                                            : '' }
                                        </Stack>
                                    </Box>
                                    : ''
                                }
                                
                                <Box 
                                    sx={{ 
                                        width: '100%',
                                        backgroundColor: 'white',
                                        borderRadius: 'xl'
                                    }}
                                >
                                    <Typography startDecorator={<CurrencyRubleIcon color='primary' />} fontSize='md' sx={{ px: 2, my: 1.5 }}>
                                        Стоимость заказа
                                    </Typography>
                                    <Divider />

                                    <Box sx={{ mx: 3, my: 2 }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography level='h4' fontWeight='xl'>
                                                Итого
                                            </Typography>
                                            <Typography level='h3' fontWeight='xl'>
                                                {sumTotalCount} ₽
                                            </Typography>
                                        </Stack>
                                        <Box sx={{ mt: 1 }}>
                                            <Button 
                                                sx={{ fontSize: 'lg', width: '100%' }}
                                                onClick={orderCreate}>
                                                    <CheckCircleOutlineIcon sx={{ mr: 0.5 }}/> Заказать
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                
                            </Stack>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>
            )}
        </Transition>
    )
}
