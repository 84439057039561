import { Avatar, Box, Button, DialogContent, DialogTitle, Grid, Input, ModalClose, ModalDialog, Stack, Typography, useTheme } from "@mui/joy";

import InputMask from 'react-input-mask';
import ItemOrder from "../ItemOrder";

import CakeIcon from '@mui/icons-material/Cake';

export default function DialogAccount({ state, user, logoutUser }) {
    const theme = useTheme();
    
    function formatDate(date, format) {
        return format.replace('mm', (date.getMonth() + 1) >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`)
                    .replace('yy', date.getFullYear())
                    .replace('dd', date.getDate());
    }

    function getEmoji(phone) {
        if (phone) {
            if (phone.slice(-1) == 0) return '../emoji/cool.png'
            else if (phone.slice(-1) == 1) return '../emoji/crazy.png'
            else if (phone.slice(-1) == 2) return '../emoji/ghost.png'
            else if (phone.slice(-1) == 3) return '../emoji/love.png'
            else if (phone.slice(-1) == 4) return '../emoji/money.png'
            else if (phone.slice(-1) == 5) return '../emoji/party.png'
            else if (phone.slice(-1) == 6) return '../emoji/robot.png'
            else if (phone.slice(-1) == 7) return '../emoji/stars.png'
            else if (phone.slice(-1) == 8) return '../emoji/tasty.png'
            else if (phone.slice(-1) == 9) return '../emoji/alien.png'
        }
    }

    return (
        <ModalDialog
            sx={{
                [theme.breakpoints.up("xl")]: {
                    width: '600px',
                    mx: 0,
                },
                [theme.breakpoints.down("xl")]: {
                    minWidth: '45%',
                    mx: 0,
                },
                [theme.breakpoints.down("lg")]: {
                    width: '60%',
                    mx: 0,
                },
                [theme.breakpoints.down("md")]: {
                    width: '80%',
                    mx: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    width: '90%',
                    p: 3,
                },
                my: 5,
                p: 4,
                borderRadius: 'xl',
                opacity: 0,
                transition: `opacity 200ms`,
                ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                }[state],
            }}
        >
            <ModalClose style={{ zIndex: 2 }} />
            <DialogTitle>
                <Typography level="h2">Аккаунт</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid container sx={{ width: '100%' }} spacing={4} columns={{ xs: 4, sm: 2, md: 4 }}>
                        <Grid xs={4} sm="auto" md="auto" lg="auto" sx={{ p: { xs: 0, md: 2 }, width: { sm: '100%', md: 'auto' } }}>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Avatar
                                    sx={{ p: 2.5, "--Avatar-size": "70px" }}
                                    alt="Аватар" src={getEmoji(user.phone)} size="lg" />
                                <Typography startDecorator={<CakeIcon color="danger"/>}>
                                    У вас 10 бонусов
                                </Typography>
                            </Stack>
                            
                        </Grid>
                        <Grid xs={4} sm={2} md sx={{ p: { xs: 0, md: 2 } }}>
                            <form>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{ width: '100%' }}
                                >
                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            Имя*
                                        </Typography>
                                        <Input sx={{ borderRadius: 'md', boxShadow: 'none', borderColor: 'neutral.300' }} type="text" placeholder="Иван" variant="outlined" />
                                    </Box>
                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            Номер телефона*
                                        </Typography>
                                        <InputMask mask="+7 (999) 999 99-99" onChange={(e) => console.log(e.target.value)}>
                                            {(inputProps) => <Input {...inputProps} sx={{ borderRadius: 'md', boxShadow: 'none', borderColor: 'neutral.300' }} type="tel" placeholder="+7 (000) 000 00-00" variant="outlined" disableUnderline />}
                                        </InputMask>
                                    </Box>
                                    
                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            Дата рождения
                                        </Typography>
                                        <Input sx={{ borderRadius: 'md', boxShadow: 'none', borderColor: 'neutral.300' }} 
                                            slotProps={{
                                                input: {
                                                    max: formatDate(new Date(Date.now()), 'yy-mm-dd'),
                                                },
                                            }}
                                            type="date" variant="outlined" />
                                    </Box>
                                </Stack>
                                
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{ mt: 3 }}
                                >
                                    <Button 
                                        variant="outlined"
                                        sx={{ m: 0, fontSize: 'md' }}
                                    >
                                        Сохранить
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color='danger'
                                        sx={{ m: 0, fontSize: 'md' }}
                                        onClick={() => logoutUser()}>
                                            Выйти
                                    </Button>
                                </Stack>
                            </form>
                        </Grid>
                    </Grid>
                    
                    <Box sx={{ width: '100%' }}>
                        <Typography level="h3" color="primary" sx={{ mb: 2 }}>Заказы</Typography>
                        <Box
                            sx={{ width: '100%', overflowY: 'scroll', height: '40vh' }}
                        >
                            <ItemOrder></ItemOrder>
                            <ItemOrder></ItemOrder>
                            <ItemOrder></ItemOrder>
                        </Box>
                    </Box>
                </Stack>
            </DialogContent>
        </ModalDialog>
    )
}