import { Box, Typography } from "@mui/joy";

// import Swiper core and required modules
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import BigCardItem from "../CardProductItem/BigCardItem";
import { useMemo } from "react";



function ProductListScroll({ productList, basket, setBasket, setObjModal, setCountBasketItem, addBasket, removeBasket }) {
    
    const list = useMemo(() => {
        console.log("Load Items");
        console.log(productList);
        return productList.map(item => 
            <SwiperSlide key={item.id}>
                <BigCardItem
                    setObjModal={setObjModal}
                    product={item}
                    basket={basket}
                    setBasket={setBasket}
                    addBasket={addBasket}
                    removeBasket={removeBasket}
                    />
            </SwiperSlide>
        )
    }, [productList, basket])


    return (
        <Swiper
            style={{ padding: '20px 20px' }}
            modules={[Navigation, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
                580: {
                    slidesPerView: 2,
                },
                880: {
                    slidesPerView: 3,
                },
                1150: {
                    slidesPerView: 4,
                },
            }}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
        >
            {list}
        </Swiper>
    )
}

export default ProductListScroll;