import React, { useMemo } from "react";
import { Box, Card, CardContent, Container, Grid, Link, Skeleton, Stack, Typography } from "@mui/joy";
import {Link as LinkRouter} from "react-router-dom";


import DevicesIcon from '@mui/icons-material/Devices';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import classes from "./Footer.module.css";
import Information from "../../../utils";

function Footer({ page_inform }) {

    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);

    return (
        <footer className={classes.footer}>
            <Container sx={{ height: '100%' }}>
                <Grid 
                    container 
                    spacing={3} 
                    sx={{flexGrow: 1}}
                    columns={{ sm: 2, xs: 6, md: 12}}>
                    {/* <Grid xs>
                        <Typography sx={{ fontWeight: 'bold' }}>Реквизиты</Typography>
                        <List sx={{ "--List-gap": "-15px" }}>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent><LinkRouter to="/"><Link color="neutral">ИП Ситников</Link></LinkRouter></ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent>ИНН: 592101783271</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent>р/с: 40802810049770063879</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent>БИК: 042202603</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent>БАНК: ПАО Сбербанк, г. Пермь</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent>к/с: 30101810900000000603</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent>ОГРНИП: 322595800043281</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent>Телефон: 8 (912) 888-10-64</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent>E-mail: e_v_ershova@mail.ru</ListItemContent>
                            </ListItem>
                        </List>
                    </Grid> */}
                    <Grid xs sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <LinkRouter to='/'>
                            <img className={classes.logo} src="../logo.png" />
                        </LinkRouter>
                    </Grid>
                    <Grid xs={4}>
                        <ul className={classes.list}>
                            <li><LinkRouter to="/"><Link color="neutral">Главная</Link></LinkRouter></li>
                            <li><Link className={classes.link_nav} color="neutral" href='#menu'>Меню</Link></li>
                            <li><Link className={classes.link_nav} color="neutral" href='#new'>Новинки!</Link></li>
                            <li><Link className={classes.link_nav} color="neutral" href='#contacts'>Контакты</Link></li>
                            <li><Link className={classes.link_nav} color="neutral" href='#delivery'>Доставка</Link></li>
                            <li><Link className={classes.link_nav} color="neutral" href='#delivery'>Политика конфиденциальности</Link></li>
                        </ul>
                    </Grid>
                    <Grid xs={4}>
                        <Typography fontWeight={'xl'}>
                            Статусы
                        </Typography>
                        <Box sx={{ m: 1 }}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <span class={'pulse_success'}></span>
                                <Typography>
                                    Доставка работает
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <span class={'pulse_success'}></span>
                                <Typography>
                                    Принимаем заказы
                                </Typography>
                            </Stack>
                        </Box>
                        <Typography fontWeight={'xl'}>
                            Контакты
                        </Typography>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0.5}
                            sx={{ mt: 1 }}
                        >
                            <Box sx={{ display: 'flex', position: 'relative', height: '25px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.phone ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <PhoneIcon color="primary" fontSize="xl"/>
                                        <Typography fontSize={'md'} fontWeight={'xl'}>
                                            {inform.phone}
                                        </Typography>
                                    </Stack>
                                </Skeleton>
                            </Box>

                            <Box sx={{ display: 'flex', position: 'relative', height: '25px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.email ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <AlternateEmailIcon color="primary" fontSize="xl"/>
                                        <Typography fontSize={'md'} fontWeight={'xl'}>
                                            {inform.email}
                                        </Typography>
                                    </Stack>
                                </Skeleton>
                            </Box>

                            <Box sx={{ display: 'flex', position: 'relative', height: '25px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.address ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <LocationOnIcon color="primary" fontSize="xl"/>
                                        <Typography fontSize={'md'} fontWeight={'xl'}>
                                            {inform.address}
                                        </Typography>
                                    </Stack>
                                </Skeleton>
                            </Box>

                            <Box sx={{ display: 'flex', position: 'relative', height: '25px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.address ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <HistoryToggleOffIcon color="primary" fontSize="xl"/>
                                        <Typography fontSize={'md'} fontWeight={'xl'}>
                                            {inform.work_time}
                                        </Typography>
                                    </Stack>
                                </Skeleton>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid xs>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >   
                            <Link underline="none" onClick={() => window.scrollTo({ top: 0 })}>
                                Вверх
                                <KeyboardArrowUpIcon sx={{ fontSize: 40 }}/>
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    )
}



export default Footer;