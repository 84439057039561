import Header from './components/UI/header/Header';
import Footer from './components/UI/footer/Footer';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import MenuPage from './pages/MenuPage';
import { useEffect, useMemo, useState } from 'react';
import Layout from './pages/Layout';
import { CssVarsProvider, extendTheme } from '@mui/joy';
import { useSinglePrismicDocument } from '@prismicio/react';
import WebLayout from './pages/webapp/WebLayout';
import WebMainPage from './pages/webapp/WebMainPage';
import WebMenuPage from './pages/webapp/WebMenuPage';
import WebContactPage from './pages/webapp/WebContactPage';
import { AnimatePresence } from 'framer-motion';


function App() {
  // const [ menu, setMenu ] = useState([
  //     { id: 1, title: 'Суши', photo: './menu/sushi.png', slugId: 'sushi' },
  //     { id: 2, title: 'Роллы', photo: './menu/roll.png', slugId: 'roll' },
  //     { id: 3, title: 'Сэты', photo: './menu/sets.jpg', slugId: 'sets' },
  //     { id: 4, title: 'Пицца', photo: './menu/pizza.png', slugId: 'pizza' },
  //     { id: 5, title: 'Супы', photo: './menu/soup.png', slugId: 'soup' },
  //     { id: 6, title: 'Салаты', photo: './menu/salat.jpg', slugId: 'salat' },
  //     { id: 7, title: 'Запечённые роллы', photo: './menu/hot_roll.png', slugId: 'hot_roll' },
  //     { id: 8, title: 'Поке', photo: './menu/poke.png', slugId: 'poke' },
  //     { id: 9, title: 'Закуски', photo: './menu/zakuski.png', slugId: 'zakuski' },
  // ]);

  const [ user, setUser ] = useState({});

  const [ basket, setBasket ] = useState([]);
  const [ allCost, setAllCost ] = useState(0);
  const [ countBasketItem, setCountBasketItem ] = useState(0);

  const [ objModal, setObjModal ] = useState({open: false, product: {}, count: undefined, setCount: undefined});

  const [ openSnackSuccess, setOpenSnackSuccess ] = useState(false);

  const [ menu, setMenu ] = useState([]);
  // Подгрузка меню
  useEffect(() => {
    console.log('LOAD ALL MENU');
    fetch('https://dummyjson.com/recipes')
    .then(res => res.json())
    .then((info) => {
      setMenu(info.recipes);
    })
  }, []);



  // Когда меняется корзина
  useMemo(() => {
    console.log('update BASKET');
    // Суммируем количество товаров
    let sum = 0;
    basket.map(itm => sum += itm.count);
    setCountBasketItem(sum);
    // Суммируем стоимость всех товаров
    let sumCost = 0;
    basket.map(itm => sumCost += itm.product.price * itm.count);
    setAllCost(sumCost);
  }, [basket]);


  // Добавить элмент в корзину
  function addBasket (count, setCount, product) {
    var basks = basket;

    const bask_id = basket.findIndex((element) => element.product.id === product.id)
    if (bask_id > -1) {
        basks[bask_id].count = count;
        setBasket([...basks]);
    }
    else {
        const newBask = {product: product, count: 1, setCount: setCount};
        setBasket([...basket, newBask]);
    }

    return count;
  }

  // Уменьшить элемент в корзине
  function removeBasket (count, product) {
      var basks = basket;
      
      const bask_id = basket.findIndex((element) => element.product.id === product.id)
      if (bask_id > -1)
          console.log(bask_id);
          if (count < 1) basks = basks.filter(p => p.product.id !== basks[bask_id].product.id);
          else basks[bask_id].count = count;
          setBasket([...basks]);

      return count;
  }

  // Оформление заказа
  function createOrder (count, product) {
    setOpenSnackSuccess(true);
    setBasket([]);
  }

  const theme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            50: '#e3f4ff',
            100: '#d1edff', // Цвет если soft вариант
            200: '#b8e3ff', // Цвет при наведении на кнопку если soft вариант
            300: '#2a95db', // Цвет бортиков если вариант outlined
            400: '#2178DD', // -
            500: '#009DFF', // Цвет фона для всех вариантов
            600: '#008ce3', // Цвет фона при наведении если solid вариант
            700: '#007ecc', // Цвет фона для всех вариантов (при нажатии)
            800: '#2F4968', // -
            900: '#2F3C4C', // -
          },
          danger: {
            50: "#fde3e4",
            100: "#facccd",
            200: "#f49999",
            300: "#e0666b",
            400: "#cc3340",
            500: "#c30013",
            600: "#b10011",
            700: "#9e000e",
            800: "#8b000b",
            900: "#780008"
          },
          neutral: {
            500: '#242424', // Цвет фона для всех вариантов
            600: '#2e2e2e', // Цвет фона при наведении если solid вариант
            700: '#1f1f1f', // Цвет фона для всех вариантов (при нажатии)
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        
        extrasmall: 300,
        small: 500,
        medium: 800,
        large: 1100,
      },
    },
    fontFamily: {
      body: 'Nunito',
      display: 'Nunito',
      fallback: 'Nunito'
    },
    components: {
      JoyButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            transition: '.2s',
            borderRadius: theme.vars.radius.lg
          })
        }
      },
    },
  });


  const [ page_inform ] = useSinglePrismicDocument('main_page')

  return (
    <CssVarsProvider
      theme={theme}
    >
      <BrowserRouter>
        <AnimatePresence>
          <Routes>
            <Route path='/' 
              element={
                <Layout 
                  openSnackSuccess={openSnackSuccess}
                  setOpenSnackSuccess={setOpenSnackSuccess}
                  
                  objModal={objModal} 
                  setObjModal={setObjModal} 
                  
                  countBasketItem={countBasketItem} 
                  setCountBasketItem = {setCountBasketItem}
                  allCost = {allCost}
                  basket={basket}
                  setBasket={setBasket}

                  addBasket={addBasket}
                  removeBasket={removeBasket}
                  createOrder={createOrder}

                  user={user}
                  setUser={setUser}
                  page_inform={page_inform}
                >
                  <MainPage 
                    menu={menu}
                    setObjModal={setObjModal}

                    setCountBasketItem={setCountBasketItem}
                    basket={basket}
                    setBasket={setBasket}
                    addBasket={addBasket}
                    removeBasket={removeBasket}
                    page_inform={page_inform}
                    />
                </Layout>
              }
            />
            <Route path="/menu/:slugMenu" 
              element={
                <Layout 
                  openSnackSuccess={openSnackSuccess}
                  setOpenSnackSuccess={setOpenSnackSuccess}
                  
                  objModal={objModal} 
                  setObjModal={setObjModal} 
                  
                  countBasketItem={countBasketItem} 
                  setCountBasketItem = {setCountBasketItem}
                  allCost = {allCost}
                  basket={basket}
                  setBasket={setBasket}

                  addBasket={addBasket}
                  removeBasket={removeBasket}
                  createOrder={createOrder}

                  user={user}
                  setUser={setUser}
                  page_inform={page_inform}
                >
                  <MenuPage 
                    setObjModal={setObjModal}
                    setCountBasketItem={setCountBasketItem}
                    basket={basket}
                    setBasket={setBasket}
                    addBasket={addBasket}
                    removeBasket={removeBasket}
                    menu={menu}
                    page_inform={page_inform}
                    />
                </Layout>
              }
            />

            <Route path='/webapp/' 
              element={
                <WebLayout 
                  openSnackSuccess={openSnackSuccess}
                  setOpenSnackSuccess={setOpenSnackSuccess}
                  
                  objModal={objModal} 
                  setObjModal={setObjModal} 
                  
                  countBasketItem={countBasketItem} 
                  setCountBasketItem = {setCountBasketItem}
                  allCost = {allCost}
                  basket={basket}
                  setBasket={setBasket}

                  addBasket={addBasket}
                  removeBasket={removeBasket}
                  createOrder={createOrder}

                  user={user}
                  setUser={setUser}
                  page_inform={page_inform}
                >
                  <WebMainPage 
                    menu={menu}
                    setObjModal={setObjModal}

                    setCountBasketItem={setCountBasketItem}
                    basket={basket}
                    setBasket={setBasket}
                    addBasket={addBasket}
                    removeBasket={removeBasket}
                    page_inform={page_inform}
                    />
                </WebLayout>
              }
            />
            <Route path="/webapp/menu/:slugMenu" 
              element={
                <WebLayout 
                  openSnackSuccess={openSnackSuccess}

                  objModal={objModal} 
                  setObjModal={setObjModal} 
                  
                  countBasketItem={countBasketItem} 
                  setCountBasketItem = {setCountBasketItem}
                  allCost = {allCost}
                  basket={basket}
                  setBasket={setBasket}

                  addBasket={addBasket}
                  removeBasket={removeBasket}
                  createOrder={createOrder}

                  user={user}
                  setUser={setUser}
                >
                  <WebMenuPage 
                    setObjModal={setObjModal}
                    setCountBasketItem={setCountBasketItem}
                    basket={basket}
                    setBasket={setBasket}
                    addBasket={addBasket}
                    removeBasket={removeBasket}
                    menu={menu}
                    page_inform={page_inform}
                    />
                </WebLayout>
              }
            />
            <Route path="/webapp/contacts/" 
              element={
                <WebLayout 
                  openSnackSuccess={openSnackSuccess}

                  objModal={objModal} 
                  setObjModal={setObjModal} 
                  
                  countBasketItem={countBasketItem} 
                  setCountBasketItem = {setCountBasketItem}
                  allCost = {allCost}
                  basket={basket}
                  setBasket={setBasket}

                  addBasket={addBasket}
                  removeBasket={removeBasket}
                  createOrder={createOrder}

                  user={user}
                  setUser={setUser}
                >
                  <WebContactPage
                    page_inform={page_inform}/>
                </WebLayout>
              }
            />
          </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </CssVarsProvider>
  );
}

export default App;