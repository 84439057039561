import React from "react";
import { Container } from '@mui/joy';

import WebMenuCardList from "../../components/MainPageUI/WebMenuCardList";

import { motion } from "framer-motion";



export default function WebMainPage({ menu }) {
    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Container
                sx={(theme) => ({
                    position: 'relative', py: 5,
                    [theme.breakpoints.down('sm')]: {
                        py: 3
                    }
                })}
            >
                {/* Блок Меню */}
                <WebMenuCardList menuList={menu} />
            </Container>
        </motion.div>
    )
}