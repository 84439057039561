import { Box, Button, Container, Link, Skeleton, Stack, Typography, useTheme } from "@mui/joy";
import React, { useMemo } from "react";
import classes from "./Header.module.css"; 
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';

import { Link as LinkRouter } from "react-router-dom";
import "./Header.module.css";
import Information from "../../../utils";

function Header({ page_inform }) {

    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);
    
    return (
        <header className={classes.header}>
            <Container sx={{'py': 1}}>
                <Stack sx={{ height: '100%' }} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack direction={'row'} sx={{ width: { xs: '100%', sm: 'auto' } }} spacing={3} justifyContent={{ xs: 'space-between', sm: 'flex-start' }} alignItems={'center'}>
                        <LinkRouter to='/'>
                            <img className={classes.logo} src="../logo.png" />
                        </LinkRouter>
                        <Stack direction={'column'} sx={{ m: 1 }} justifyContent={'center'} alignItems={{ xs: 'flex-end', sm: 'flex-start' }}>
                            <Typography 
                                level="title-md"
                                color="primary"
                                fontWeight={'xl'}
                                sx={{ m: 0 }}
                            >
                                <Skeleton sx={{ minWidth: '50px' }} loading={ inform.phone ? false : true }>
                                    {inform.work_time}
                                </Skeleton>
                            </Typography>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <span class={'pulse_danger'}></span>
                                <Typography level={'title-sm'}>
                                    Доставка работает
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <span class={'pulse_success'}></span>
                                <Typography level={'title-sm'}>
                                    Принимаем заказы
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    
                    <Stack 
                        direction={'row'} 
                        spacing={1} 
                        alignItems={'center'}
                        sx={(theme) => ({
                            display: 'flex',
                            [theme.breakpoints.down('sm')]: {
                                display: 'none'
                            }
                        })} 
                    >
                        <Stack 
                            direction="column" 
                            justifyContent="center"
                            alignItems="flex-end"
                            sx={{ height: '100%' }} 
                        >
                            

                            <Link 
                                level="h3"
                                href="tel:+79001111111" 
                                underline="none" 
                                fontWeight={'lg'} 
                                color="neutral"
                                sx={{ fontWeight: 'xl' }}>
                                    +7 (922) 234-23-22
                            </Link>
                        
                            <Typography 
                                sx={{ m: 0 }}
                            >
                                можно заказать в <Link href="https://t.me/tsunami_perm_bot" color="danger">@tsunami_perm_bot</Link>
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </header>
    )
}

export default Header;