import { Chip, Container, Grid, Typography } from "@mui/joy";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import BigCardItem from "../../components/CardProductItem/BigCardItem";
import ContactBlock from "../../components/MainPageUI/ContactBlock";

import { motion } from "framer-motion";


export default function WebContactPage({ page_inform }) {

    useEffect(() => {
        window.scrollTo({ 
            top: 0, 
            behavior: 'smooth' 
        });
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Container>
                <ContactBlock page_inform={page_inform} />
            </Container>
        </motion.div>
    )
}