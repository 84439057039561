import { Box, CircularProgress, Grid, Skeleton, Stack } from "@mui/joy";
import LinkCardMenuItem from "./CardMenuItem/LinkCardMenuItem";
import { useMemo } from "react";



export default function MenuCardList({ menuList }) {
    const menu = useMemo(() => {
        return menuList;
    }, [menuList])
    
    return (
        <Box id={"menu"} sx={(theme) => ({
            mt: 5, flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                mt: 3
            }
        })}
        >
            { menu.length >= 1 ?
                <Grid container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, small: 6, sm: 6, md: 6, large: 8 }}
                >
                    {menu.map(item =>
                        <Grid className='animation-grid' xs={2} small={3} sm={2} md={2} large={2} key={item.id}>
                            <LinkCardMenuItem title={item.name} photo={item.image} slugId={item.id}/>
                        </Grid>
                    )}
                </Grid>
                :
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress size="md" />
                </Stack>
            }
        </Box>
    )
}